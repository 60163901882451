<template>
  <div>
    <div>
      <van-nav-bar :title="$t('video.video')">
        <template #right>
          <van-icon name="chat-o" size="24" color="#fff" @click="toLogin('/ServiceOnline')" />
        </template>
      </van-nav-bar>
    </div>
    <div>
      <van-tabs
        v-model="active"
        title-inactive-color="rgb(68, 40, 137)"
        title-active-color="rgb(197, 113, 207)"
        line-height="10px"
        line-width="40px"
        @change="onChange"
      >
        <van-tab
          :title="item.name"
          :name="item.id"
          title-style="font-size:16px;line-height:50px;"
          v-for="(item,index) in videolitem"
          :key="item.id"
          @click="toPlayVideo(item.id)"

        >
        <template #title v-if="index==0">

          <div style="display: flex">
            <div style="">{{ item.name }}</div>
            <div style="color: rgb(197, 113, 207);font-weight: bold;background: url('/img/new.png') right  top 5px / 100% no-repeat;width: 30px;">
          </div>


          </div>

        </template>
          <div>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-model="loading"
                :finished="finished"

                @load="onLoad"
              >
                <div class="list-item">
                  <div class="movie-list-item" v-for="(i,key) in data" :key="key" @click="toPlayVideo(i.id)">

                    <div
                      class="cover_img van-image van-image--round"
                      style="width: 100%; height: 100px;"
                    >
                      <img
                        :src="i.vod_pic"
                        class="van-image__img"
                      />
                    </div>
                    <div class="movie-list-item-bottom" style="bottom: 33px;">
                      <div class="movie-time-div">
                        <span
                          >{{ i.vod_name }}</span
                        ><span style="font-size: 12px;">{{$t("video.play")}} :10000+</span> <!--heilong {{ i.count }} -->
                      </div>
                    </div>
                  </div>

                </div>

              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div style="height: 55px;"></div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      data: [],
      page: 0,
      id: 888,
    };
  },
  methods: {
    onLoad(isRefresh = false) {
      if (isRefresh) {
        this.page = 0;
        this.data = [];
      }

      this.loading = true;

      this.getVideoList().then(() => {
        this.loading = false;
        if (this.refreshing) {
          this.refreshing = false;
          Toast("새로고침 성공");
        }
      });
    },
    onChange(name) {
      this.id = name;
      this.finished = false;
      this.loading = true;
      this.page = 0;
      this.data = [];
      this.onLoad(true);

    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.loading = true;
      this.onLoad(true);
    },
    toPlayVideo(id) {
      this.$router.push({ path: "/PlayVideo", query: { id } });
    },
    getVideoList() {
    return new Promise((resolve, reject) => {
      this.$http({
        method: "get",
        data: { id: this.id, page: this.page },
        url: "video_list",
      }).then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.data = this.data.concat(res.data.data);
          this.page++;
          resolve();
        } else {
          this.finished = true;
          resolve();
        }
      }).catch((error) => {
        console.error("Error fetching video list:", error);
        reject(error);
      });
    });
    },
    toLogin(str) {
      this.$router.push({ path: str});
    },
    getVideoClass() {
      this.$http({
        method: "get",
        url: "video_class",
      }).then((res) => {
        const lanng = localStorage.getItem("lang");
        this.videolitem = res.data;
        const obj = { id: 888, name: "最新,new,最新の,new" };
        this.videolitem.unshift(obj);
        this.videolitem.forEach((item) => {
          const parts = item.name.split(",");
          lanng == 'kr'? item.name = parts[0]:lanng == 'en'? item.name = parts[1]: lanng == 'ja'?item.name = parts[2]:item.name = parts[3]

        });


      });
    },
  },
  created() {
    this.getVideoClass();

  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background: linear-gradient(30deg, #7d76ef, #d63f8c);
}
/deep/ .van-tabs__wrap {
  height: 13vw;
  padding-bottom: 10px;
}
/deep/ .van-tabs__line {
  background-color: #c571cf;
}
/deep/ .van-loading__text {
  font-size: 32px;
  color: #000;

}
/deep/ .van-list__finished-text {
  font-size: 32px;
  color: #000;

}
/deep/ .van-pull-refresh__head {
  height: 100px;
}
/deep/ .van-pull-refresh__text {
  font-size: 32px; /* 设置字体大小 */
  color: #000;

}

.list-item {
  display: flex;
  width: calc(100% - 7vw);
  margin: 10px auto;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.movie-list-item {
  width: calc(50% - 16px);
  margin-bottom: -7vw;
}
.cover_img {
  border-radius: 20px;
}
.movie-list-item-bottom {
  position: relative;
  width: 100%;
  bottom: 6vw;
}
.movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
  height: 8vw;
  color: #fff;
  border-radius: 0 0 40px 40px;
}
.movie-list-item-bottom div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 24vw;
  padding-left: 16px;
  font-size: 3vw;
}
.activeBg{
  color: rgb(197, 113, 207);
    font-weight: bold;
    background: url('/img/new.png') right top 5px / 30% no-repeat;
}
</style>
