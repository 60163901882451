<template>
  <div>
    <div>
      <van-nav-bar :title="$t('my.detail')"  class="nav-bar" left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="22" color="#fff" />
        </template>
      </van-nav-bar>
    </div>
    <!--van-nav-bar :title="$t('my.detail')"  class = "nav-bar">
      <template #left>
        <van-icon name="arrow-left" size="22" color="#fff" />
      </template>

    </van-nav-bar-->

    <div class="feiziInfo">
      <div class="topInfo">
        <div><span>{{ data.xuanfei_name }}</span></div>
        <div style="color: #6b22b3; font-size: 3vw; margin: 10px 0;">
          {{$t("index.height")}}:{{ data.hight }} {{$t("index.bust")}}：{{ data.bust }}
        </div>

        <div style="width: 50%;">
          <div class="item" v-for="(item,index) in data.flagArray" :key="index">{{item}}</div>

        </div>
        <!----><!---->
        <div>
          <span style="color: rgb(107, 34, 179); font-size: 14px;"
            >{{$t("index.toll")}}：</span
          >
          <van-rate v-model="data.number" readonly    color="#ffd21e" size="18px" />
        </div>
        <div
          style="color: rgb(107, 34, 179); font-size: 14px; margin: 4px 0px;"
        >
        {{$t("index.residence")}}：{{ data.address }}
        </div>
        <div class="yuyueBox" @click="getUserGameList">{{$t("index.instant_booking")}}</div>
        <div class="dianzanimg" @click="clickPraise">
          <div class="van-image" style="width: 60px; height: 60px;">
            <img
              src="/img/zan.c28032c8.png"
              class="van-image__img"
              style="object-fit: cover;"
            />
          </div>
          <p style="color: rgb(107, 34, 179); margin: 0px;">
            {{ data.dianzan }}
          </p>
        </div>
      </div>
      <div style="padding-bottom: 4vw; background-color: #ebedf0;"></div>
      <div class="cenInfo">
        <div style="width: 50%;">
          <van-tabs
            @click="onClick"
            title-inactive-color="rgb(54, 52, 217)"
            title-active-color="rgb(202, 51, 88)"
            line-width="50px"
          >
            <van-tab
              :title="$t('index.photos')"
              name="phone"
              title-style="font-size:14px;line-height:50px"
            ></van-tab>
            <van-tab
             :title="$t('index.video')"
              name="video"
              title-style="font-size:14px;line-height:50px"
            ></van-tab>
          </van-tabs>
        </div>
        <div v-show="!showMsg">
          <div >
            <div  class="imgswiper" >
              <div
                v-for="(i,index) in data.img_url" :key="index"
                style="width: 100px; height: 100px; margin-right: 10px;"
              >
                <div
                  @click="onClickImg(index)"
                  :class="['imgI van-image', { active: currentIndex === index }]"

                  style="width: 100px; height: 100px;"
                >
                  <img
                    :src="i"
                    class="van-image__img"
                    style="object-fit: cover;"
                  />
                </div>
              </div>


            </div>
            <div

              class="van-image"
              style="width: 100%; height: 635px;"
            >
              <img
               :src="currentImage"
                class="van-image__img"
                style="object-fit: cover;"
              />
            </div>
          </div>
        </div>
        <div v-show="showMsg">

        <div class="movie-video" style="margin: 10px;">
      <video id="my-video" controls class="video-js">

        <source :src="data.pvideo" type="video/mp4" />
      </video>
    </div>

        </div>
      </div>
      <div style="padding-bottom: 2vw; background-color: #ebedf0;"></div>
      <div  class="btmInfo">
        <div  style="color: rgb(42, 20, 104); margin-bottom: 10px; font-weight: bold;"></div>
        <div  style="color: rgb(83, 69, 137);">{{ data.jianjie }}</div></div>
    </div>
    <div style="padding-bottom: 2vw; background-color: #ebedf0;"></div>
  </div>
</template>

<script>
import videojs from 'video.js';
export default {
  data() {
    return {
      showMsg:false,
      data: {},
      currentIndex: 0,
      currentImage: '',
      isPraised: false,
      player: null,
    };
  },
  methods: {
    getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
        this.data = res.data;
        const lanng = localStorage.getItem("lang");
        // this.data.flag.forEach(item => {
        //   const parts = item.flag.split('|')
        //   item.flag = parts[0]
        //   item.flagArray = item.flag.split('，');

        // })
        const parts = this.data.flag.split('|')
        console.log(parts,987)
        lanng == 'zh'? this.data.flag = parts[0]:lanng == 'en'? this.data.flag = parts[1]: lanng == 'ja'?this.data.flag = parts[2]:this.data.flag = parts[3]
        this.data.flagArray = this.data.flag.split('，');
        this.currentImage = this.data.img_url[0];
        console.log(this.data,998)
			});
    },

    onClick(name) {
      console.log(name);
      if (name === 'phone') {
        this.showMsg = false
        if (this.player) {
          this.player.pause();
         // 确保 player 被置为空
        }
      } else {
        this.showMsg = true


      }

    },
    onClickImg(index) {
      this.currentIndex = index;
      this.currentImage = this.data.img_url[index];
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    clickPraise() {
      if (this.isPraised) {
        // 如果已经点赞，则减1
        this.data.dianzan --;
        this.isPraised = false;
      } else {
        // 如果未点赞，则加1
        this.data.dianzan ++;
        this.isPraised = true;
      }
    },
    getUserGameList(){
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res=>{
        if(res.code === 200){
          this.$toast('まずアクティベーションタスクを完了してください。');//this.$toast('먼저 활성화 작업을 완료하십시오.');
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    this.getxuanfeidata();

  },
  mounted() {
    this.player = videojs('my-video');
  },
  beforeDestroy() {
    // 销毁video.js播放器实例，避免内存泄漏
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background: linear-gradient(30deg, #7d76ef, #d63f8c) !important;
}
.van-nav-bar .van-icon {
  color: #fff !important;
}
.feiziInfo {
  background: #ebedf0;
}
.topInfo {
  position: relative;
  padding: 4vw 40px;
  background: #fff;
}
.feiziInfo .topInfo div:first-child span:first-child {
  color: #2c1868;
  font-size: 5vw;
}
.topInfo .item {
  background: linear-gradient(50deg, #9b54ca, #e6557f);
  color: #fff;
  display: inline-block;
  border-radius: 20px;
  margin-right: 30px;
  font-size: 24px;
  padding: 16px;
  margin-bottom: 30px;
}
.yuyueBox {
  position: absolute;
  right: 4vw;
  bottom: 40px;
  color: #fff;
  padding: 20px 30px;
  background: linear-gradient(20deg, #e73266, #ee5380);
  border-radius: 7vw;
}
.dianzanimg {
  position: absolute;
  top: 4vw;
  right: 7vw;
  text-align: center;
}
.cenInfo {
  padding: 4vw 20px;
  background: #fff;

}
.imgswiper{
  width: 100%;
    overflow-x: scroll;
    margin: 4vw 0;
    display: flex;
    flex-wrap: nowrap;
}
.imgswiper .active{
  border: 10px solid #e54878;
}
.btmInfo{
  padding: 4vw 40px;
    background: #fff;


}
::v-deep .movie-video .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}
.video-js {
  width: 100%;
  height: 336px;
  font-size: 24px;

}
</style>
