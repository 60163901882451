<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#7e5678"
    :border="true"
    inactive-color="#979799"
    z-index="999"
  >
  <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <span>{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/indexed.png' : '/img/footer/index.png'"
          :alt="$t('foorter.index')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? '/img/footer/indexed.png' : '/img/footer/index.png'"
          :alt="$t('foorter.index')"
          :class="$t('foorter.index')"
          style="height: 4rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    <!-- 影院 -->
    <van-tabbar-item to="/Video">
      <span>{{ $t("foorter.subscribe") }} </span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/yingyuaned.png' : '/img/footer/yingyuan.png'"
          :alt="$t('foorter.subscribe')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? '/img/footer/yingyuaned.png' : '/img/footer/yingyuan.png'"
          :alt="$t('foorter.subscribe')"
          :class="$t('foorter.subscribe')"
          style="height: 4rem"
          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>

    <!-- 视频 -->
    <van-tabbar-item to="/Choose">
      <span>{{ $t("foorter.xuanfei") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/xuanfeied.png' : '/img/footer/xuanfei.png'"
          :alt="$t('foorter.xuanfei')"
          v-show="show !== 2"
        />
        <img
         :src="props.active ? '/img/footer/xuanfeied.png' : '/img/footer/xuanfei.png'"
          :alt="$t('foorter.xuanfei')"
          :class="$t('foorter.xuanfei')"
          style="height: 4rem"
          v-show="show === 2"
        />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/Lottery">
      <span>{{ $t("foorter.video") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/toupiaoed.png' : '/img/footer/toupiao.png'"
          :alt="$t('foorter.video')"
          v-show="show !== 3"
        />
        <img
         :src="props.active ? '/img/footer/toupiaoed.png' : '/img/footer/toupiao.png'"
          :alt="$t('foorter.video')"
          :class="$t('foorter.video')"
          style="height: 4rem"
          v-show="show === 3"
        />
      </template>
    </van-tabbar-item>
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span>{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/myed.png' : '/img/footer/my.png'"
          :alt="$t('foorter.my')"
          v-show="show !== 4"
        />
        <img
          :src="props.active ? '/img/footer/myed.png' : '/img/footer/my.png'"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
          style="height: 4rem"
          v-show="show === 4"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "video") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "choose") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "Lottery") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "video") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "choose") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "Lottery") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 75px;
.van-tabbar {
  height: @tabbar-height;
}
.van-tabbar-item__icon img {
  height: 8vw;
}
.van-tabbar-item {
  font-size: 26px;
}
.tui {
  width: 4rem;
  height: 4rem!important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
</style>
